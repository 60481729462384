<template>
  <div>
    <v-main>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-container fluid>
              <v-sheet
                color="indigo darken-4"
                dark
                elevation="5"
                rounded
                height=""
                width=""
                class="mt-3"
              >
                <v-row justify="start">
                  <v-col cols="1">
                    <v-icon
                      class="ml-4 mt-2"
                      @click="
                        $store.state.mostrarCasos = true;
                        $store.state.mostrarDatos = false;
                        $router.go(-1);
                      "
                    >
                      mdi-chevron-left
                    </v-icon>
                  </v-col>
                  <v-col cols="8" md="8" xs="8" sm="8">
                    <div
                      v-text="'Folders: ' + parametrosRuta.title"
                      class="headline font-weight-light mt-1"
                    ></div>
                  </v-col>

                  <!-- <v-col cols="3" md="3" xs="3" sm="3">
                      <v-btn small fab text color="blue" class="mb-1">
                        <v-icon> mdi-email-edit </v-icon>
                      </v-btn>
                      <v-btn small fab text color="green" class="mb-1">
                        <v-icon> mdi-file-move-outline </v-icon>
                      </v-btn>
                      <v-btn small fab text color="red" class="mb-1">
                        <v-icon> mdi-delete </v-icon>
                      </v-btn>
                    </v-col> -->
                </v-row>
              </v-sheet>
            </v-container>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-sheet width="" height="" elevation="" rounded>
              <v-container fluid class="fill-height">
                <v-row>
                  <v-col>
                    <v-sheet class="mt-n10">
                      <v-row>
                        <v-col>
                          <v-sheet  elevation="" rounded="">
                            <v-row>
                              <v-col>
                                <div
                                  v-text="'Meta data'"
                                  class="headline font-weight-light text-center blue darken-4 white--text rounded mx-5"
                                ></div>
                              </v-col>
                            </v-row>
                          </v-sheet>
                        </v-col>
                      </v-row>
                      <v-container fluid class="fill-height mx-10">
                        <div
                          v-text="'Detalles'"
                          class="header font-weight-light text-left"
                        ></div>
                        <v-row>
                          <v-col>
                            <v-row>
                              <v-col>
                                <v-container>
                                  <v-row>
                                    <v-col cols="1">
                                      <v-icon>mdi-file-document</v-icon>
                                    </v-col>
                                    <v-col>
                                      <div
                                        class="title font-weight-bold"
                                        v-text="'Tipo documental'"
                                      ></div>
                                      <div
                                        v-text="parametrosRuta.tipoDocumental"
                                        class="body-1 font-weight-light text-left ml-5"
                                      ></div>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-col>
                              <v-col>
                                <v-container>
                                  <v-row>
                                    <v-col cols="1">
                                      <v-icon>mdi-account</v-icon>
                                    </v-col>
                                    <v-col>
                                      <div
                                        class="title font-weight-bold"
                                        v-text="'Enviado por'"
                                      ></div>
                                      <div
                                        v-text="
                                          parametrosRuta.data.nombre_supervisor
                                        "
                                        class="subtitle font-weight-light text-left ml-5"
                                      ></div>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-container>
                                  <v-row>
                                    <v-col cols="1">
                                      <v-icon>mdi-calendar-clock</v-icon>
                                    </v-col>
                                    <v-col>
                                      <div
                                        class="title font-weight-bold"
                                        v-text="'Fecha limite de entrega'"
                                      ></div>
                                      <div
                                        v-text="
                                          parametrosRuta.subtitle.substring(25)
                                        "
                                        class="subtitle font-weight-light text-left ml-5"
                                      ></div>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-col>
                              <v-col>
                                <v-container>
                                  <v-row>
                                    <v-col cols="1">
                                      <v-icon>mdi-calendar-check</v-icon>
                                    </v-col>
                                    <v-col>
                                      <div
                                        class="title font-weight-bold"
                                        v-text="'Fecha de asignación'"
                                      ></div>
                                      <div
                                        v-text="
                                          parametrosRuta.data
                                            .fecha_asignacion_supervisor
                                        "
                                        class="subtitle font-weight-light text-left ml-5"
                                      ></div>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                        <!-- <v-row v-if="parametrosRuta.data.estado == 'Pendiente'">
                          <v-col>
                            <v-btn
                              color="red"
                              text
                              @click="dialogo_rechazar_caso"
                              >Rechazar caso</v-btn
                            >
                          </v-col>
                          <v-col>
                            <v-btn text color="indigo" @click="aceptar_caso"
                              >Aceptar caso</v-btn
                            >
                          </v-col>
                        </v-row> -->
                        <v-row>
                          <v-col cols="12">
                            <v-list class="ml-n7">
                              <v-list-item>
                                <v-list-item-avatar>
                                  <v-icon color="blue darken-4"
                                    >mdi-information</v-icon
                                  >
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title> Información publica del caso</v-list-item-title>
                                  <v-list-item-subtitle>
                                    La información pública del caso
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                  <v-btn
                                    small
                                    text
                                    class="mr-15"
                                    color="blue darken-4"
                                    @click="revisar_archivos_caso"
                                  >
                                    REVISAR
                                  </v-btn>
                                </v-list-item-action>
                              </v-list-item>
                            </v-list>
                            <!-- <div
                              v-text="'Información publica del caso'"
                              class="header font-weight-bold text-left mr-2"
                            ></div>
                            <div
                              v-text="
                                'La información pública del caso, son todos aquellos archivos bases los cuales podras revisar siempre que quieras'
                              "
                              class="ml-3 font-weight-medium text-body-2"
                            ></div> -->
                          </v-col>
                          <!-- <v-col>
                            <v-btn
                              text
                              color="indigo"
                              class="mt-5"
                              @click="revisar_archivos_caso"
                              >Revisar información</v-btn
                            >
                          </v-col> -->
                        </v-row>
                      </v-container>
                    </v-sheet>
                  </v-col>
                </v-row>
                <!-- <v-sheet color="white" class="mt-10">
                  <v-expansion-panels v-model="panel" multiple>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        Meta data
                        <template v-slot:actions>
                          <v-icon color="primary"> $expand </v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col>
                            <h3>
                              Tipo documental:
                              {{ parametrosRuta.tipoDocumental }}
                            </h3>
                          </v-col>
                          <v-col>
                            <h3>{{ parametrosRuta.subtitle }}</h3>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <h3>
                              Enviado por:
                              {{ parametrosRuta.data.nombre_supervisor }}
                            </h3>
                          </v-col>
                          <v-col>
                            <h3>
                              Fecha de asignacion:
                              {{
                                parametrosRuta.data.fecha_asignacion_supervisor
                              }}
                            </h3>
                          </v-col>
                        </v-row>
                        <v-row v-if="parametrosRuta.data.estado == 'Pendiente'">
                          <v-col>
                            <v-btn
                              color="red"
                              text
                              @click="dialogo_rechazar_caso"
                              >Rechazar caso</v-btn
                            >
                          </v-col>
                          <v-col>
                            <v-btn
                              block
                              dark
                              color="indigo"
                              @click="aceptar_caso"
                              >Aceptar caso</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        Informacion publica del caso
                        <template v-slot:actions>
                          <v-icon color="primary"> $expand </v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <h4>
                          La informacion publica del caso, son todos aquellos
                          archivos bases los cuales podras revisar siempre que
                          quieras
                        </h4>
                        <v-btn
                          block
                          dark
                          color="indigo"
                          @click="revisar_archivos_caso"
                          >Revisar informacion</v-btn
                        >
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-sheet> -->
              </v-container>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
      <v-dialog persistent v-model="dialogo_rechazar_caso">
        <v-card>
          <v-card-text>
            <h3 style="text-align: center; color: red">Rechazar caso</h3>
            <p>
              Estas seguro que deseas rechazar el caso? Una vez rechazado no
              podras volver a verlo en tu bandeja de entrada de casos quedara
              almacenado en la ventana de casos rechazados y se le debera enviar
              una notificacion al supervisor con el motivo de su rechazo
            </p>
          </v-card-text>
          <v-textarea
            label="Escribe el motivo del rechazo"
            outlined
            v-model="comentario"
            rows="3"
          ></v-textarea>
          <v-card-actions>
            <v-btn color="red" text @click="dialogo_rechazar_caso = false"
              >cerrar</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              @click="rechazar_caso"
              text
              color="red"
              :disabled="comentario == ''"
            >
              Rechazar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </div>
</template>
<script>
//const axios = require("axios").default;
//import TablaEmpleados from '../../components/supervisor/TablaEmpleados'
import acciones_casos_empleados from "../../mixins/acciones_casos_empleados";
export default {
  mixins: [acciones_casos_empleados],
  components: {
    //TablaEmpleados,
  },
  data() {
    return {
      parametrosRuta: {},
      panel: [0],
      dialogo_rechazar_caso: false,
      comentario: "",
    };
  },
  created() {
    this.parametrosRuta = this.$route.params.data;

    console.log(this.parametrosRuta);
    console.log(this.$route.params);
  },
  methods: {
    revisar_archivos_caso() {
      //Hacer una redireccion a una vista que contenga toda la informacion publica del caso
      //Tambien permitira anexar archivos
      this.$router.push({
        name: "revisar_caso_empleado",
        params: { id: this.$route.params.id, data: this.parametrosRuta },
      });
    },
  },
};
</script>