var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-container',{attrs:{"fluid":""}},[_c('v-sheet',{staticClass:"mt-3",attrs:{"color":"indigo darken-4","dark":"","elevation":"5","rounded":"","height":"","width":""}},[_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',{staticClass:"ml-4 mt-2",on:{"click":function($event){_vm.$store.state.mostrarCasos = true;
                      _vm.$store.state.mostrarDatos = false;
                      _vm.$router.go(-1);}}},[_vm._v(" mdi-chevron-left ")])],1),_c('v-col',{attrs:{"cols":"8","md":"8","xs":"8","sm":"8"}},[_c('div',{staticClass:"headline font-weight-light mt-1",domProps:{"textContent":_vm._s('Folders: ' + _vm.parametrosRuta.title)}})])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{attrs:{"width":"","height":"","elevation":"","rounded":""}},[_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-sheet',{staticClass:"mt-n10"},[_c('v-row',[_c('v-col',[_c('v-sheet',{attrs:{"elevation":"","rounded":""}},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"headline font-weight-light text-center blue darken-4 white--text rounded mx-5",domProps:{"textContent":_vm._s('Meta data')}})])],1)],1)],1)],1),_c('v-container',{staticClass:"fill-height mx-10",attrs:{"fluid":""}},[_c('div',{staticClass:"header font-weight-light text-left",domProps:{"textContent":_vm._s('Detalles')}}),_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',[_vm._v("mdi-file-document")])],1),_c('v-col',[_c('div',{staticClass:"title font-weight-bold",domProps:{"textContent":_vm._s('Tipo documental')}}),_c('div',{staticClass:"body-1 font-weight-light text-left ml-5",domProps:{"textContent":_vm._s(_vm.parametrosRuta.tipoDocumental)}})])],1)],1)],1),_c('v-col',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',[_vm._v("mdi-account")])],1),_c('v-col',[_c('div',{staticClass:"title font-weight-bold",domProps:{"textContent":_vm._s('Enviado por')}}),_c('div',{staticClass:"subtitle font-weight-light text-left ml-5",domProps:{"textContent":_vm._s(
                                        _vm.parametrosRuta.data.nombre_supervisor
                                      )}})])],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',[_vm._v("mdi-calendar-clock")])],1),_c('v-col',[_c('div',{staticClass:"title font-weight-bold",domProps:{"textContent":_vm._s('Fecha limite de entrega')}}),_c('div',{staticClass:"subtitle font-weight-light text-left ml-5",domProps:{"textContent":_vm._s(
                                        _vm.parametrosRuta.subtitle.substring(25)
                                      )}})])],1)],1)],1),_c('v-col',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',[_vm._v("mdi-calendar-check")])],1),_c('v-col',[_c('div',{staticClass:"title font-weight-bold",domProps:{"textContent":_vm._s('Fecha de asignación')}}),_c('div',{staticClass:"subtitle font-weight-light text-left ml-5",domProps:{"textContent":_vm._s(
                                        _vm.parametrosRuta.data
                                          .fecha_asignacion_supervisor
                                      )}})])],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-list',{staticClass:"ml-n7"},[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"color":"blue darken-4"}},[_vm._v("mdi-information")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Información publica del caso")]),_c('v-list-item-subtitle',[_vm._v(" La información pública del caso ")])],1),_c('v-list-item-action',[_c('v-btn',{staticClass:"mr-15",attrs:{"small":"","text":"","color":"blue darken-4"},on:{"click":_vm.revisar_archivos_caso}},[_vm._v(" REVISAR ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.dialogo_rechazar_caso),callback:function ($$v) {_vm.dialogo_rechazar_caso=$$v},expression:"dialogo_rechazar_caso"}},[_c('v-card',[_c('v-card-text',[_c('h3',{staticStyle:{"text-align":"center","color":"red"}},[_vm._v("Rechazar caso")]),_c('p',[_vm._v(" Estas seguro que deseas rechazar el caso? Una vez rechazado no podras volver a verlo en tu bandeja de entrada de casos quedara almacenado en la ventana de casos rechazados y se le debera enviar una notificacion al supervisor con el motivo de su rechazo ")])]),_c('v-textarea',{attrs:{"label":"Escribe el motivo del rechazo","outlined":"","rows":"3"},model:{value:(_vm.comentario),callback:function ($$v) {_vm.comentario=$$v},expression:"comentario"}}),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.dialogo_rechazar_caso = false}}},[_vm._v("cerrar")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"red","disabled":_vm.comentario == ''},on:{"click":_vm.rechazar_caso}},[_vm._v(" Rechazar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }