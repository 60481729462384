
const axios = require('axios').default
var acciones_casos_empleados = {
    methods: {
        async aceptar_caso(comentario) {
            //hdasa
            //Cambiar datos en el endpoint
            this.$store.commit('auth', 'parse')
            console.log(this.$store.state.dataUser.dataUser.nombre, this.$store.state.dataUser.dataUser.identificacion)
            // 01-03 Motivo de reasignacion la url debe ir en put
            let url
            if (this.parametrosRuta.data.tipo == 'Pendiente') {
                url = 'https://audidoc.educandote.co/parse/classes/prueba01casos/'
            } else {
                url = 'https://audidoc.educandote.co/parse/classes/prueba01RespuestasSupervisores/'
            }
            try {
                let obj = {
                    nombre: this.$store.state.dataUser.dataUser.nombre,
                    identificacion: this.$store.state.dataUser.dataUser.identificacion,
                    correo: this.$store.state.dataUser.dataUser.correo,
                    cargo: this.$store.state.dataUser.dataUser.rango,
                }
                obj = JSON.stringify(obj)
                let response = await axios.put(url + this.parametrosRuta.data.id_caso_endpoint,
                    {
                        "responsables": {
                            "__op": "AddUnique", //Podemos remover colocando aqui "Remove"
                            "objects": [
                                obj
                            ]
                        }
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'X-Parse-REST-API-Key': 'LASDK823JKHR87SDFJSDHF8DFHASFDF',
                            'X-Parse-Application-Id': 'KSDJFKASJFI3S8DSJFDH',
                            'Authorization': this.$store.state.header
                        }
                    })

                console.log(response)


                console.log('Aceptar caso')
                let respuesta = await this.enviar_estado_respuesta('Aceptado', comentario, [])
                //Devuelve true para exitoso y false para fallido y null para exitoso pero con error en notificacion al correo
                return respuesta
            } catch (error) {
                console.log(error)

                this.$store.commit('error', 'Ha ocurrido un error aceptando el caso. Por favor intentalo mas tarde')
                return false
            }


        },
        async rechazar_caso(comentario) {
            console.log(comentario)
            console.log('Rechazar caso')
            let respuesta = await this.enviar_estado_respuesta('Rechazado', comentario, [])
            //Devuelve true para exitoso y false para fallido y null para exitoso pero con error en notificacion al correo
            return respuesta

        },
        async responder_caso(comentario,formulario) {
            console.log(comentario)
            console.log('Responder caso')
            console.log(this.datos_adjuntos)
            let datos_adjuntos = []
            for (let index = 0; index < this.$store.state.archivos_seleccionados.length; index++) {
               if(this.$store.state.archivos_seleccionados[index].tipo!='Formulario'){
                   datos_adjuntos.push(JSON.stringify(this.$store.state.archivos_seleccionados[index])) //Puse el STRINGY
               }
            }
            console.log(datos_adjuntos)
            let respuesta = await this.enviar_estado_respuesta('Respondido', comentario,datos_adjuntos,formulario)
            //Devuelve true para exitoso y false para fallido y null para exitoso pero con error en notificacion al correo
            return respuesta

        },

        async enviar_estado_respuesta(estado, comentario, datos_adjuntos,formulario) {
            this.$store.commit('auth', 'parse')
            console.log('Formulario')
            console.log(formulario)
            //Esta funcion que da la fecha se debe mover al store y debe cambiar
            //Porque estas fechas las toma desde el computador y necesitamos las del servidor

            //Quizas podamos agregar algo que se llame hora para tener una mejor evidecia y registros de las respuestas
            var f = new Date();
            var fecha = f.getFullYear() + "-" + (("0" + (f.getMonth() + 1)).slice(-2)) + "-" + ("0" + f.getDate()).slice(-2)
            try {
                let response = await axios.post('https://audidoc.educandote.co/parse/classes/prueba01RespuestasEmpleados/',
                    {
                        "sector": this.$store.state.dataUser.dataUser.sector,

                        "nombre_supervisor": this.parametrosRuta.data.nombre_supervisor,
                        "correo_supervisor": this.parametrosRuta.data.correo_supervisor,
                        "identificacion_supervisor": this.parametrosRuta.data.identificacion_supervisor,

                        "nombre_empleado": this.$store.state.dataUser.dataUser.nombre,
                        "identificacion_empleado": this.$store.state.dataUser.dataUser.identificacion,
                        "correo_empleado": this.$store.state.dataUser.dataUser.correo,

                        "fecha": fecha,
                        "fecha_int": Date.parse(new Date(fecha)),

                        "nombre_documento": this.parametrosRuta.data.nombre_documento,
                        "tipo_documental": this.parametrosRuta.data.tipo_documental,
                        "token_caso": this.parametrosRuta.data.token,
                        "estado": estado,
                        "comentario": comentario,
                        "datos_adjuntos": datos_adjuntos, //Array del tipo {nombre, token, tipo, autor,sector} que contendra los datos para constuir la respuesta
                        "formulario": formulario
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'X-Parse-REST-API-Key': 'LASDK823JKHR87SDFJSDHF8DFHASFDF',
                            'X-Parse-Application-Id': 'KSDJFKASJFI3S8DSJFDH',
                            'Authorization': this.$store.state.header
                        }
                    })
                console.log(response)
                if(estado=='Respondido'){
                    let formulario_lleno = false
                    for (let index = 0; index < formulario.parametros.length; index++) {
                        if(formulario.parametros[index].value!=""){
                          formulario_lleno = true
                        }
                      }

                    if(formulario_lleno){
                        try {
                            console.log(formulario)
                            let envio_formulario = await this.$store.dispatch('llamado_put',
                            {url: 'https://audidoc.educandote.co/parse/classes/prueba01casos/'+this.parametrosRuta.data.id_caso_endpoint,
                            body: {
                                    "formularios": {
                                        "__op": "AddUnique", //Podemos remover colocando aqui "Remove"
                                        "objects": [
                                            {
                                                formulario: formulario,
                                                nombre_empleado: this.$store.state.dataUser.dataUser.nombre,
                                            }
                                        ]
                                    }
                                },
                            tipo_header: 'parse'
                            })
                            console.log(envio_formulario)
                        console.log('Envio de formulario a endpoint supervisor')
                        } catch (error) {
                            console.log(error)
                        }
                        
                    }
                }
               

                this.parametrosRuta.data.estado = estado

                let correo = await this.notificar_al_correo(estado, comentario)
                if (correo) {
                    this.$store.commit('exitoso', 'El caso ha sido ' + estado.toLowerCase() + ' exitosamente')
                    return true
                } else {
                    this.$store.commit('alerta', 'El caso ha sido respondido exitosamente, pero no se le ha podido notificar al correo electronico del supervisor')
                    return null
                }

            } catch (error) {
                console.log(error)
                return false
            }

        },
        //Esta funcion de notificar al correo se puede hacer un mixin commun o una funcion en el store
        //Ya que supervisor y empleado hacen uso de ella
        async notificar_al_correo(tipo, comentario) {

            console.log(tipo)
            /*
            var username = "7c234d3ef69483eda10d1cd5718e69a1";
            var password = "a28863fbbfb59889700ef0039abbbc14";
            var credentials = btoa(username + ":" + password);
            var basicAuth = "Basic " + credentials;
            */
            let correo
            if (tipo == 'Rechazado') {
                correo = "<br /><h3><strong>El caso <strong/>:" +
                    this.parametrosRuta.data.nombre_documento +
                    "</h3><br/><h3><strong>Tipo de caso:<strong/> " +
                    this.parametrosRuta.data.tipo_documental +
                    "</h3><br/>Ha sido" + tipo + "Con motivo de: " + comentario
            } else {
                correo = "<br /><h3><strong>El caso <strong/>:" +
                    this.parametrosRuta.data.nombre_documento +
                    "</h3><br/><h3><strong>Tipo de caso:<strong/> " +
                    this.parametrosRuta.data.tipo_documental +
                    "</h3><br/>Ha sido" + tipo
            }
            try {
                let body = {"operation":"sendMail","key":"7c234d3ef69483e","token":"ad140c41a20cb6c",
                "para": this.parametrosRuta.data.correo_supervisor,"asunto":  this.parametrosRuta.data.nombre_documento+' '+ this.parametrosRuta.data.tipo_documental +' '+ new Date() ,"mensaje":correo}
                var response = await axios.post("https://audidoc.educandote.co/mailjet/enviar_correo.php",
                    body,
                    {
                        headers: {
                            "Content-Type": "application/json ",
                        },
                    })
                console.log(response)
                return true
            } catch (error) {
                console.log(error)

                return false
            }
        },

    }
}
export default acciones_casos_empleados